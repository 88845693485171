const ERROR_CODE = [
  {
    "msg": "MODULE_NOT_FOUND",
    "desc": "Method definition not found",
    "code": 501
  },
  {
    "msg": "WRONG_INPUT_PARAMETERS_COUNT",
    "desc": "Wrong number of input parameters",
    "code": 400
  },
  {
    "msg": "WRONG_INPUTS_SYNTAX",
    "desc": "Wrong input parameters",
    "code": 400
  },
  {
    "msg": "NOT_AUTHORIZED",
    "desc": "User not authenticated",
    "code": 401
  },
  {
    "msg": "FORBIDDEN",
    "desc": "Action is forbidden",
    "code": 403
  },
  {
    "msg": "DEVICE_CREATE_ERROR",
    "desc": "Cannot create new device",
    "code": 510
  },
  {
    "msg": "CREATE_ERROR",
    "desc": "Cannot create new row",
    "code": 510
  },
  {
    "msg": "UPDATE_ERROR",
    "desc": "Cannot update row",
    "code": 510
  },
  {
    "msg": "DEVICE_NOT_FOUND",
    "desc": "Used device token not found",
    "code": 404
  },
  {
    "msg": "INVALID_LOGIN_DETAILS",
    "desc": "Invalid login details used",
    "code": 400
  },
  {
    "msg": "MASTER_TOKEN_EXPIRED",
    "desc": "You have to singIn",
    "code": 401
  },
  {
    "msg": "DATABASE_INSERT_ERROR",
    "desc": "Data not inserted to database",
    "code": 510
  },
  {
    "msg": "ACCOUNT_DISABLED",
    "desc": "Your Account has been disabled by your master",
    "code": 403
  },
  {
    "msg": "SQL CONNECTION FAILED",
    "desc": "Cannot connect to database",
    "code": 503
  },
  {
    "msg": "DUPLICATE_EMAIL_FOUND",
    "desc": "Duplicate email address found",
    "code": 400
  },
  {
    "msg": "DUPLICATE_LOGIN_NAME_FOUND",
    "desc": "Duplicate login name found",
    "code": 400
  },
  {
    "msg": "DUPLICATE_VALUE_ENTRY",
    "desc": "Duplicate value entry",
    "code": 400
  },
  {
    "msg": "SELF_REGISTRATION_DISABLED",
    "desc": "User type is not allowed to self registration",
    "code": 400
  },
  {
    "msg": "INVALID_EMAIL_FORMAT",
    "desc": "User email is invalid",
    "code": 400
  },
  {
    "msg": "INVALID_PHONE_NUMBER_FORMAT",
    "desc": "User phone number is invalid",
    "code": 400
  },
  {
    "msg": "LANGUAGE_NOT_SUPPORTED",
    "desc": "Selected language is unsupported",
    "code": 400
  },
  {
    "msg": "NOT_ALLOWED_IDENT_TYPE",
    "desc": "Selected identification type is not allowed",
    "code": 403
  },
  {
    "msg": "ITEM_NOT_FOUND",
    "desc": "Requested item not found",
    "code": 404
  },
  {
    "msg": "INVALID_FILE_TYPE",
    "desc": "File type not supported by API",
    "code": 500
  },
  {
    "msg": "FILE_SIZE_EXCEEDED",
    "desc": "File size exceeded the maximum size permitted by API",
    "code": 500
  },
  {
    "msg": "CANNOT_CONNECT_TO_UBYPORT",
    "desc": "Ubyport api is down",
    "code": 500
  },
  {
    "msg": "CANNOT_SEND_FORM",
    "desc": "Cannot send ubyport form response error",
    "code": 500
  },
  {
    "msg": "INVALID_EXPORT_FORMAT",
    "desc": "Export format not supported by API",
    "code": 400
  },
  {
    "msg": "RESET_HASH_EXPIRED",
    "desc": "You have just 1 hour to verify your email",
    "code": 400
  },
  {
    "msg": "EMAIL_NOT_VERIFIED",
    "desc": "Email provided in account not verified yet",
    "code": 400
  },
  {
    "msg": "EMAIL_NOT_VERIFIED",
    "desc": "Email provided in account not verified yet",
    "code": 400
  },
  {
    "msg": "PASSWORDS_NOT_MATCH",
    "desc": "Provided passwords are not same",
    "code": 400
  },
  {
    "msg": "FORMS_NOT_APPROVED",
    "desc": "Data in forms not approved by host",
    "code": 400
  },
  {
    "msg": "INVALID_SETTINGS_TYPE",
    "desc": "Notification settings type not supported",
    "code": 400
  },
  {
    "msg": "NOTHING_TO_UPDATE",
    "desc": "All informations in database is same",
    "code": 400
  },
  {
    "msg": "DUPLICATE_INVITATION_CONTACT",
    "desc": "Invitation on this contact was already sent",
    "code": 400
  },
  {
    "msg": "PERSONS_COUNT_EXCEEDED",
    "desc": "Persons count exceeded ",
    "code": 400
  },
  {
    "msg": "INVALID_INTEGRATION_TYPE",
    "desc": "Integration type don't allowed",
    "code": 400
  },
  {
    "msg": "DUPLICATE_INTEGRATION",
    "desc": "Duplicate integration found",
    "code": 400
  },
  {
    "msg": "RESERVATION_CANT_BE_DELETED",
    "desc": "Reservation was sent to police or already inactive (can be deleted only before it ends) ",
    "code": 400
  },
  {
    "msg": "WELCOMER_ASSIGNED",
    "desc": "Can't be deleted still assigned to some property ",
    "code": 400
  },
  {
    "msg": "DUPLICATE_BUSINESS_ID",
    "desc": "Account for this business already exits ",
    "code": 400
  },
  {
    "msg": "NOT_GET_INVOICE",
    "desc": "Can't get invoice ",
    "code": 400
  },
  {
    "msg": "BLACKLIST_BUSINESS_ID",
    "desc": "Your Business ID in blacklist ",
    "code": 451
  },
  {
    "msg": "BLACKLIST_PHONE_NUMBER",
    "desc": "Your Phone Number in blacklist ",
    "code": 451
  },
  {
    "msg": "BLACKLIST_EMAIL",
    "desc": "Your Email address in blacklist ",
    "code": 451
  },
  {
    "msg": "NOT_ABLE_TO_RESERVE",
    "desc": "Your reservation for this month not acceptable ",
    "code": 451
  },
  {
    "msg": "NOT_ABLE_TO_CHANGE_ADDR",
    "desc": "You cannot change the address of the property. But you can add a new property.",
    "code": 451
  },
  {
    "msg": "AIR_LOCK_ERROR",
    "desc": "AIRLOCK need verification ",
    "code": 400
  },
  {
    "msg": "USER_NOT_FOUND",
    "desc": "User not found ",
    "code": 400
  },
  {
    "msg": "NOT_FOUND",
    "desc": "Item not found ",
    "code": 400
  },
  {
    "msg": "DUPLICATE_PHONE_FOUND",
    "desc": "Duplicate phone number found ",
    "code": 400
  },
  {
    "msg": "FOUND_AS_MASTER_GUEST",
    "desc": "ERROR - Found as master guest",
    "code": 400
  },
];

export default (code, note) => {
  const getCodeObj = ERROR_CODE.find(k => k.msg === code);
  return getCodeObj ? getCodeObj.desc : note || code  || 'Something wrong happened. Please try again later, we are working on fixing this issue';
}