import { 
  getItemFromStorage, 
  BESTGUEST_STORE_NAME,
} from '../helpers/localStorage';
import { isEmpty } from 'lodash';

export function useBaseUrl() {
  return process.env.REACT_APP_BASE_URL;
}

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || '';

export const ruianParamsKey = process.env.REACT_APP_RUIAN_PARAMS_KEY || '';

export const locizeProjectId = process.env.REACT_APP_LOCIZE_PROJECT_ID || '';

export const locizeApiKey = process.env.REACT_APP_LOCIZE_API_KEY || '';


export function useToken() {
  const existingUser = getItemFromStorage(BESTGUEST_STORE_NAME);
  if (isEmpty(existingUser)) {
    // window.location.href = '/login';
    return '';
  }
  return existingUser.tokens;
}

export function base64ToImage(dataurl, fileName = 'singature.png') {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

export function blobToBase64(blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl = reader.result;
    var base64 = dataUrl.split(',')[1];
    callback(`data:image/png;base64,${base64}`);
  };
  reader.readAsDataURL(blob);
};
