export const ReservationUrls = {
  CREATE_RESERVATIONS: '/reservations/createReservation',
  GET_RESERVATIONS: '/reservations/getReservations',
  CREATE_CHECK_IN_FORM: '/reservations/submitCheckinForm',
};

export const GET_COUNTRIES_URL = '/staticData/getCountries';
export const EXPORT_GUEST_BOOK_URL = '/guestBook/getGuestBook';

// export const URL_GET_COUNTRIES = 'staticData/getCountries';
export const URL_GET_COUNTRIES = 'staticData/getRestCountries';
export const URL_GET_VISA_COUNTRY = 'staticData/getVisaCountry';
export const URL_GET_PASSPORT_DATA = 'staticData/getPassportData';

export const AccommodationUrl = {
  CREATE_NEW_LISTING: '/listings/createListing',
  GET_LISTING_BY_ACCOMMODATION: '/listings/getAccommodationListings',
  UPDATE_ACCOMMODATION_URL: '/accommodations/editAccommodation',
};

export const AccountUrls = {
  LOG_OUT: '/user/signOut',
  LOG_IN: '/user/signIn',
};

export const ubyportUrls = {
  GET_CLIENT_DETAILS_URL: '/client/getClientDetails',
  INSERT_UBY_PORT_URL: '/client/insertUbyportLoginData',
  UPDATE_UBY_PORT_URL: '/client/editUbyportLoginData',
};

export const ProfileInfoUrl = {
  CREATE_RESIDENTIAL_CLIENT_URL: '/client/createResidentialClient',
  UPDATE_RESIDENTIAL_CLIENT_URL: '/client/updateResidentialClient',
  GET_RESIDENTIAL_CLIENTS_URL: '/client/getResidentialClients',
  GET_CLIENT_INFO_URL: '/client/getClientInfo',
  UPDATE_CLIENT_INFO_URL: '/client/updateClientInfo',
  DELETE_CLIENT_INFO_URL: '/client/deleteResidentialClient',
};

export const URL_RESERVATIONS = 'reservations';
export const URL_ACCOMMODATIONS = 'accommodations';
export const URL_LISTINGS = 'listings';
export const URL_PROPERTIES = 'accommodations';
export const URL_CLIENT = 'client';
export const URL_NOTIFICATION = 'notifications';
export const URL_USER = 'user';

export const URL_CLIENT_GET_INFO = `${URL_CLIENT}/getClientInfo`;
export const URL_CLIENT_GET_RESIDENTIAL_CLIENTS = `${URL_CLIENT}/getResidentialClients`;
export const URL_GET_CLIENT_SIGNATURE = `${URL_CLIENT}/getClientSignature`;

export const URL_USER_PROFILE = `${URL_USER}/getProfile`;
export const URL_USER_UPDATE_PROFILE = `${URL_USER}/updateProfile`;
export const URL_GET_HAS_ACCOUNT = `${URL_USER}/hasAccount`;
export const URL_SAVE_USER_INFO = `${URL_USER}/saveUserInfo`;
export const URL_SIGN_UP_WITH_EMAIL = `${URL_USER}/signupWithEmail`;
export const URL_RESET_PASSWORD_EMAIL = `${URL_USER}/resetPasswordEmail`;
export const URL_EMAIL_VERIFY = `${URL_USER}/emailVerify`;
export const URL_VERIFY_PASSWORD_OTP = `${URL_USER}/verifyPasswordOtp`;

export const URL_ACCOMMODATIONS_ADD_NEW = `${URL_ACCOMMODATIONS}/addAccommodation`;

export const URL_MY_TRIPS = `${URL_RESERVATIONS}/myTrips`;
export const URL_RESERVATIONS_GET_ALL = `${URL_RESERVATIONS}/getReservationsGuest`;
export const URL_GET_INVITATIONS_GUEST = `${URL_RESERVATIONS}/getInvitationsGuest`;
export const URL_RESERVATIONS_GET_DETAIL = `${URL_RESERVATIONS}/getReservationDetailGuest`;
export const URL_RESERVATIONS_CREATE = `${URL_RESERVATIONS}/createReservation`;
export const URL_RESERVATIONS_CHECK_IN_FORM = `${URL_RESERVATIONS}/submitCheckinForm`;
export const URL_RESERVATIONS_UPDATE = `${URL_RESERVATIONS}/updateReservation`;
export const URL_SHARE_RESERVATION = `${URL_RESERVATIONS}/shareReservation`;
export const URL_UPDATE_CHECK_IN_FORM = `${URL_RESERVATIONS}/changeReservationData`;
export const URL_GET_CHECK_IN_FORM = `${URL_RESERVATIONS}/getCheckinForm`;
export const URL_GET_SIGNATURE = `${URL_RESERVATIONS}/getCheckinFormSignature`;
export const URL_GET_CHECKIN_FORM_SIGNATURE = `${URL_RESERVATIONS}/getCheckinFormSignature`;
export const URL_RESERVATION_CREATE_PUBLIC = `${URL_RESERVATIONS}/createPublicReservation`;
export const URL_INVITATION_GUEST_DETAILS = `${URL_RESERVATIONS}/getInvitationGuestDetails`;
export const URL_ADD_MASTER_GUEST = `${URL_RESERVATIONS}/addMasterGuest`;

export const URL_LISTINGS_CREATE = `${URL_LISTINGS}/createListing`;
export const URL_LISTINGS_GET_PROPERTY = `${URL_LISTINGS}/getAccommodationListings`;
export const URL_UPDATE_LISTING = `${URL_LISTINGS}/updateListing`;
export const URL_DELETE_LISTING = `${URL_LISTINGS}/deleteListing`;

export const URL_PROPERTIES_GET_ONE = `${URL_PROPERTIES}/getAccommodation`;
export const URL_PROPERTIES_GET_ALL = `${URL_PROPERTIES}/getAccommodations`;
export const URL_UPDATE_PROPERTY = `${URL_PROPERTIES}/editAccommodation`;
export const URL_GET_WELCOMER = `${URL_PROPERTIES}/getAccommodationDetail`;

export const URL_CHANGE_PASSWORD = 'user/changePassword';
export const URL_GUEST_BOOK_EXPORT = 'guestBook/getGuestBook';

export const URL_LOCAL_FEES = 'localFees';
export const URL_GET_LOCAL_FEES = `${URL_LOCAL_FEES}/getLocalFees`;
export const URL_LOCAL_FEE_GET_TIMES = `${URL_LOCAL_FEES}/getTimes`;

export const URL_CAPACITY_FEES = 'capacityFee';
export const URL_GET_CAPACITY_FEES = `${URL_CAPACITY_FEES}/getCapacityFees`;
export const URL_CAPACITY_FEE_GET_TIMES = `${URL_CAPACITY_FEES}/getTimes`;

export const URL_LISTINGS_GET_BY_PROPERTY_ID = `${URL_LISTINGS}/getListingsSelect`;
export const URL_UPDATE_NOTIFY_BASE = `${URL_NOTIFICATION}/updateNotificationBaseSettings`;
export const URL_UPDATE_NOTIFY_SETTING = `${URL_NOTIFICATION}/updateNotificationSettings`;
export const URL_ADD_NOTIFY_CONTACT = `${URL_NOTIFICATION}/addNotificationsContacts`;
export const URL_GET_NOTIFICATION_SETTING = `${URL_NOTIFICATION}/getNotificationsSettings`;
export const URL_DELETE_NOTIFICATION_CONTACT = `${URL_NOTIFICATION}/deleteNotificationsContacts`;
export const URL_DELETE_RESERVATION = `${URL_RESERVATIONS}/deleteReservation`;
export const URL_DELETE_INVITATION = `${URL_RESERVATIONS}/deleteInvitation`;
export const URL_DELETE_CHECK_IN_FORM = `${URL_RESERVATIONS}/removeCheckinForm`;

export const URL_RESERVATION_TO_POLICE = `${URL_RESERVATIONS}/reservations/sendReservationToPolice`;