import { combineReducers } from 'redux';
import reservationsList from './reservationsList';
import invitationsList from './invitationsList';
import reservationDetail from './reservationDetail';
import checkinFormDetail from './checkinFormDetail';
import myTrips from './myTrips';
import invitationGuestDetails from './invitationGuestDetails';

export default combineReducers({
  reservationsList,
  invitationsList,
  myTrips,
  invitationGuestDetails,
  detail: reservationDetail,
  checkinFormDetail: checkinFormDetail,
});
