import { find, isEmpty, replace, includes } from 'lodash';
import { parsePhoneNumber } from 'react-phone-number-input/libphonenumber';
import { format, differenceInDays, addDays } from 'date-fns';

export function getCountryName(countriesList, countryCode) {
  let countryObj = find(countriesList, ct => ct.id === countryCode);
  return isEmpty(countryObj) ? '' : countryObj.name;
}

export function formatPhone(data: any) {
  if (data) {
    try {
      const { countryCallingCode, nationalNumber } = parsePhoneNumber(data);
      // console.log(`00 ${countryCallingCode} ${nationalNumber}`);
      return `00 ${countryCallingCode} ${nationalNumber}`;
    } catch (error) {
      return `00${data.replace("+", " ")}`;
    }
  }
  return '';
}

export function convertPhoneToInput(phone) {
  try {
    let _slipt_arr = phone.split(' ');
    if (_slipt_arr.length > 1) {
      _slipt_arr.splice(_slipt_arr, 1);
      return `+${_slipt_arr.join('')}`;
    }
    return phone;
  } catch (error) {
    return phone ? replace(`${phone.replace(/\s/g, '')}`, '00', '+') : '';
  }

  return '';
}

export function truncate(str, max, sep) {

  // Default to 10 characters
  max = max || 10;

  var len = str.length;
  if (len > max) {

    // Default to elipsis
    sep = sep || "...";

    var seplen = sep.length;

    // If seperator is larger than character limit,
    // well then we don't want to just show the seperator,
    // so just show right hand side of the string.
    if (seplen > max) {
      return str.substr(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    var n = -0.5 * (max - len - seplen);

    // This gives us the centerline.
    var center = len / 2;

    var front = str.substr(0, center - n);
    var back = str.substr(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;

  }

  return str;
}

export function getDiffDate(checkInDate: string) {
  return differenceInDays(
    addDays(checkInDate, 3),
    format(new Date(), 'yyyy-MM-dd'),
    // format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  );
}

export const deadlineCustom = (checkInDate, t) => {
  // const { t } = useTranslation();
  const dateOfDeadline = getDiffDate(checkInDate);
  let _time;
  if (dateOfDeadline === 0) {
    _time = `${t('today')}`;
  }
  if (dateOfDeadline === -1) {
    _time = `${t('yesterday')}`;
  }
  if (dateOfDeadline === 1) {
    _time = `${t('tomorrow')}`;
  }
  if (dateOfDeadline < -1) {
    _time = `${Math.abs(dateOfDeadline)} ${t('days ago')}`;
  }
  if (dateOfDeadline > 1) {
    _time = `${t('in')} ${dateOfDeadline} ${t('days')}`;
  }
  return _time;
}

export function reFormatPhone(data: any) {
  return data.replace("00 ", "+") || '';
}

export const RETURN_URl = 'returnUrl';

export function getRedirectUrl(href) {
  return href && new URL(href).searchParams.get(RETURN_URl) || '/';
}

export function loginRedirect(pathName, search = '') {
  return pathName && pathName !== '/' ? `/login?${RETURN_URl}=${pathName}${search}` : '/login';
}

export function addressPickerValue(address) {
  if (address && (address.street || address.houseNumber || address.district || address.city || address.state || address.postalCode)) {
    return `${address.street || ''} ${address.houseNumber || ''}, ${address.district || ''}, ${address.city || ''}, ${address.state || ''} ${address.postalCode || ''}`;
  }
  return '';
}

export function comparePriority (a, b) {
  const _props_a = a.props.children;
  const _props_b = b.props.children;
  if (_props_a && _props_a.length > 0 && _props_a[0] && _props_b && _props_b.length > 0 && _props_b[0]) {
    if (
      _props_a[0].props && includes(_props_a[0].props.className, 'SORT') && _props_a[0].props.children &&
      _props_b[0].props && includes(_props_b[0].props.className, 'SORT') && _props_b[0].props.children
    ) {
      return _props_a[0].props.children < _props_b[0].props.children ? -1 : 1;
    }
  }
  return a < b ? -1 : 1;
};