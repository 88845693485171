import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import 'nprogress/nprogress.css';
import App from './App';
import { Provider } from 'react-redux';
import { initStore, history } from './redux/configureStore';
import {
  getItemFromStorage,
  BESTGUEST_STORE_NAME,
} from './helpers/localStorage';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeConfig from './themeConfig';
import Firebase, { FirebaseContext } from './components/Firebase';
import i18n from 'i18next';
import './i18n';
import format from 'date-fns/format';

const existingUser = getItemFromStorage(BESTGUEST_STORE_NAME);
let initialState;
if (existingUser) {
  initialState = {
    account: {
      detail: existingUser,
    },
  };
} else {
  initialState = {};
}


class FrDateFnsUtils extends DateFnsUtils {
  startOfMonth(date) {
    return moment(date).startOf('month').toDate();
  }
  getDatePickerHeaderText(date) {
    return format(date, 'd MMMM', { locale: this.locale })
  }
}

const store = initStore(initialState);
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<CircularProgress size={60} color="primary" />}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={themeConfig}>
          <MuiPickersUtilsProvider moment={moment} utils={FrDateFnsUtils}>
            {/* <Suspense fallback={<CircularProgress size={60} color="primary" />}> */}
            <FirebaseContext.Provider value={new Firebase()}>
              <App />
            </FirebaseContext.Provider>
            {/* </Suspense> */}
            
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
