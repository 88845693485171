import axios from 'axios';
import qs from 'qs';
import { isEmpty, omit, has } from 'lodash';
import { useBaseUrl, useToken, base64ToImage } from './helperHooks';

export function getDataAfterSend(method, url, params, token = useToken().authToken, downloadFile = false, hasAuth = true) {
  method = method.toLowerCase();
  const BASE_URL = useBaseUrl();

  const authToken = hasAuth ? token : '';

  let fullUrl = `${BASE_URL}/${url}`;
  if (method === 'get') {
    fullUrl = isEmpty(params)
      ? `${BASE_URL}/${url}`
      : `${BASE_URL}/${url}?${qs.stringify(params)}`;
  }
  const paramsData = params && params.params ? params.params : params;
  const _dataSend = JSON.stringify({
    ...omit(paramsData, ['signature', 'passport', 'headers', 'coverImage'])
  });

  const _signatureSend = has(params, 'signature') && params.signature && base64ToImage(params.signature);
  const _coverImageSend = has(params, 'coverImage') && params.coverImage;
  let _passportSend = null;
  try {
    _passportSend = has(params, 'passport') && params.passport && base64ToImage(params.passport, 'passport.png');
  } catch (err) {
    _passportSend = has(params, 'passport') && params.passport;
  }

  let _data = null;

  let headers = {
    Authorization: `${authToken}`,
    ...has(params, 'headers') ? params.headers : { 'content-type': 'application/x-www-form-urlencoded' },
  };
  if (has(params, 'headers') && params.headers['Content-Type'] === 'multipart/form-data') {

    _data = null;
    _data = new FormData();
    _data.append('data', _dataSend);
    _signatureSend && _data.append('signature', _signatureSend);
    _passportSend && _data.append('passport', _passportSend);
    _coverImageSend && _data.append('coverImage', _coverImageSend);
    // return axios[method](fullUrl, _data, {headers: headers});
  } else {
    _data = qs.stringify({
      data: _dataSend,
      ..._signatureSend && { signature: params.signature },
      ..._passportSend && { passport: params.passport },
      ..._coverImageSend && { passport: params.coverImage },
    });
  }


  return {
    method,
    headers: headers,
    data: _data,
    url: fullUrl,
    responseType: !!downloadFile ? 'blob' : null,
  };
}

export function callApi(method, url, params, downloadFile, hasAuth = true) {
  const _getNewData = getDataAfterSend(method, url, params, useToken().authToken, downloadFile, hasAuth);

  if (has(params, 'headers') && params.headers['Content-Type'] === 'multipart/form-data') {
    return axios[_getNewData.method](_getNewData.url, _getNewData.data, { headers: _getNewData.headers });
  }

  return axios(_getNewData);
}

export function uploadFile(url, data) {
  const BASE_URL = useBaseUrl();
  const authToken = useToken().authToken;
  let fullUrl = `${BASE_URL}/${url}`;
  const options = {
    method: 'post',
    headers: {
      Authorization: `${authToken}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
    url: fullUrl,
  };
  return axios(options);
}
