import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_INVITATIONS_GUEST,
  GET_INVITATIONS_GUEST_SUCCESS,
  GET_INVITATIONS_GUEST_FAILURE,
} from '../../actions/reservation';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_INVITATIONS_GUEST]: state => {
    state.loading = true;
  },
  [GET_INVITATIONS_GUEST_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [GET_INVITATIONS_GUEST_FAILURE]: (state, action) => {
    state.loading = false;
    state.data = [];
  },
});
