import createImmerReducer from '../../redux/immer-reducer';
import {
  FETCH_MY_TRIPS,
  FETCH_MY_TRIPS_SUCCESS,
  FETCH_MY_TRIPS_FAILURE,
} from '../../actions/reservation';

const initialState = {
  data: [],
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [FETCH_MY_TRIPS]: state => {
    state.loading = true;
  },
  [FETCH_MY_TRIPS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [FETCH_MY_TRIPS_FAILURE]: (state, action) => {
    state.loading = false;
    state.data = [];
  },
});
