import createImmerReducer from '../../redux/immer-reducer';
import {
  GET_CHECK_IN_FORM,
  GET_CHECK_IN_FORM_SUCCESS,
  GET_SIGNATURE_URL,
  GET_SIGNATURE_URL_SUCCESS,
  REMOVE_DEFAULT_SIGNATURE,
} from '../../actions/reservation';

const initialState = {
  data: {
    bookedBy: {},
    forms: [],
    listingId: 0,
    sentToPolice: false,
  },
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [GET_CHECK_IN_FORM]: state => {
    state.loading = true;
  },
  [GET_CHECK_IN_FORM_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
  [GET_SIGNATURE_URL_SUCCESS]: (state, action) => {
    state.data.signatureUrl = null;
    state.data.fileId = null;
    state.data.signature = action.res;
  },
  [REMOVE_DEFAULT_SIGNATURE]: (state, action) => {
    state.data.signatureUrl = null;
    state.data.signature = null;
  },
});
