import createImmerReducer from '../../redux/immer-reducer';
import {
  INVITATION_GUEST_DETAILS,
  INVITATION_GUEST_DETAILS_SUCCESS,
} from '../../actions/reservation';

const initialState = {
  data: {},
  loading: false,
  error: '',
};
export default createImmerReducer(initialState, {
  [INVITATION_GUEST_DETAILS]: state => {
    state.loading = true;
  },
  [INVITATION_GUEST_DETAILS_SUCCESS]: (state, action) => {
    state.loading = false;
    state.data = action.res;
  },
});
