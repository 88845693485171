import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GET_CLIENT } from '../actions/account';
import { REFRESH_TOKEN } from '../features/account/actionTypes';
import { callApi } from '../custom-hooks/apiHook';
import getErrorName from '../shared/const/errorCode';
import {
  removeItemFromStorage,
  getItemFromStorage,
  storeItem,
  BESTGUEST_STORE_NAME,
} from '../helpers/localStorage';
import { LOGIN_SUCCESS } from '../features/account/actionTypes';
import { push } from 'connected-react-router';
// import { refreshToken } from '../features/account/actions';
import { isString } from 'lodash';
import { getRedirectUrl, loginRedirect } from '../helpers';

const DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
// const ERRORS = [DEVICE_NOT_FOUND, VALIDATION_ERROR];
const POST_METHOD = 'post';
// const GET_METHOD = 'get';

const ToastMsg = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div>{t(message)}</div>
  )
};

export const showToast = (msg, note = 'Error', type = 'error') => {
  setTimeout(() => {
    toast[type](<ToastMsg message={getErrorName(msg, note)} />, {
      toastId: msg,
      preventDuplicate: msg,
    });
  }, 1000);
}

export async function handleError(error, dispatch, resumeAction, params, method) {
  if (error.response) {
    const errors = error.response.data.errors;
    if (error.response.status === 401) {
      if (errors && errors.length > 0 && errors[0].msg === NOT_AUTHORIZED) {
        dispatch({ type: REFRESH_TOKEN, resumeAction, params });
        setTimeout(() => {
          dispatch(resumeAction(params));
        }, 1500);
      } else {
        removeItemFromStorage(BESTGUEST_STORE_NAME);
        let loginUrl = loginRedirect(getRedirectUrl(window.location.href), window.location.search);
        dispatch(push(loginUrl));
      }
    } else {
      if (errors && errors.length > 0 && errors[0]) {
        // errors[0].msg !== DEVICE_NOT_FOUND &&
        //   (!(method === POST_METHOD && errors[0].msg === VALIDATION_ERROR) || isString(errors[0].note)) &&
        //   showToast(isString(errors[0].note) ? errors[0].note : errors[0].msg);
        errors[0].msg !== DEVICE_NOT_FOUND &&
          !(method === POST_METHOD && errors[0].msg === VALIDATION_ERROR) &&
          showToast(errors[0].msg, errors[0].note);
        return await error.response.data;
      } else if (errors) {
        showToast(errors.msg, errors.note);
        return await error.response.data;
      } else {
        showToast('');
        return false;
      }
    }
  } else if (error.request) {
    showToast(`${error.request}`);
  } else {
    showToast(`${error.request}`);
  }
  return await error.response || 'error';
}

export const makeRequest = (
  actionType,
  method,
  url,
  params,
  requestName,
  isUpdating,
  isFile = false,
  hasAuth = true,
) => async (dispatch, getState) => {

  // params && Object.keys(params).map(v => {
  //   !params[v] && delete params[v];
  // });

  if (method === 'post') {
    Object.keys(params).map(key => {
      params[key] && isString(params[key]) && (params[key] = params[key].trim());
    })
  }

  try {
    const res = await callApi(method, url, params, isFile, hasAuth);
    dispatch({
      type: `${actionType}_success`,
      res: !!isUpdating ? params : (isFile ? res.data : res.data.data),
    });
    if (actionType === GET_CLIENT && res.data) {
      let _get_bestguest = getItemFromStorage(BESTGUEST_STORE_NAME);
      let new_bestguest = Object.assign({}, _get_bestguest, { clientInfoFilled: res.data && res.data.data.clientInfoFilled === 1 });
      storeItem(BESTGUEST_STORE_NAME, new_bestguest);
      dispatch({
        type: LOGIN_SUCCESS,
        response: new_bestguest,
      });
    }
    return await res && res.data;
  } catch (err) {
    if(err.response.data.errors[0].code == 451){
        return 
    }
    dispatch({
      type: `${actionType}_failure`,
      res: err.response && err.response.data && err.response.data.errors,
    });
    return await handleError(err, dispatch, requestName, params, method)
  }
};
