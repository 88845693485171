// @flow
import React from 'react';
import Routes from './routes';
import { GlobalStyle } from './styles/reset-css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';

class App extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         language: props.i18n.language
    //     }
    // }

    // changeLanguage = event => {
    //     const { i18n } = this.props;
    //     console.log(event.target.value);
    //     // i18n.changeLanguage(event.target.value);
    //     this.setState({ language: event.target.value });
    // };

    render() {
        return (
            <div>
            <Hidden xsDown>
              <div className="App">
                <GlobalStyle />
                <ToastContainer />
                <Routes />
              </div>
            </Hidden>
            <Hidden smUp>
              <div>
                <GlobalStyle />
                <ToastContainer />
                <Routes />
              </div>
            </Hidden>
          </div>
        );
    }
};

export default withTranslation()(App);
