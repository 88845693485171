import { createMuiTheme } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';

export const primaryColor = "#00162a";
export const lightPrimaryColor = "#002c54";
export const secondaryColor = "#f16d36";
export const lightSecondaryColor = "#f6a039";

const themeConfig = createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    shape: {
      borderRadius: 5,
    },
    palette: {
      primary: {
        main: blue[500],
        mainColor: primaryColor,
        mainGradient: `linear-gradient(to right, ${primaryColor} 0%,${lightPrimaryColor} 100%)`,
        light: lightPrimaryColor,
        contrastText: '#fff',
      },
      secondary: {
        main: secondaryColor,
        mainGradient: `linear-gradient(to right, ${secondaryColor} 0%,${lightSecondaryColor} 100%)`,
        light: lightSecondaryColor,
        contrastText: '#fff',
      },
      default: {
        main: blue[500],
        light: blue[300],
        contrastText: '#fff',
      },
    },
    status: {
      // danger: 'orange',
    },
    zIndex: {
      tooltip: 99
    },
    overrides: {
      MuiStepIcon: {
        root: {
          "&$completed": {
            color: green[500]
          },
          "&$active": {
            color: green[500]
          },
          "&$disabled": {
            color: "red"
          }
        },
      },
      MuiTypography: {
        root: {
          // textTransform: 'capitalize',
        }
      },
      MuiTooltip:{
        tooltip: {
          fontSize: '0.875rem',
        }
      },
      MuiCircularProgress: {
        root: {
          marginLeft: 10,
        }
      }
    },
  });

export default themeConfig;