//@flow
import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import accReducers from '../features/account/reducers';
import modalReducer from '../shared/reducers';
import accomodationReducer from '../features/accomodation/reducers/reducers';
// import reservation from '../features/reservation/reducers';
import checkInForm from '../features/reservation/reducers/checkInForm';
import countriesReducer from '../features/reservation/reducers/countriesReducer';
import countries from '../reducers/countryReducer';
import global from '../reducers/globalReducer';
import visaCountry from '../reducers/visaCountryReducer';
import propertyReducer from '../reducers/property';
import clientReducer from '../reducers/account';
import userProfile from '../reducers/account/userProfile';
import reservation from '../reducers/reservation';
import listingsReducer from '../reducers/listingsReducer';
import accomodationDetailReducer from '../features/accomodation/reducers/reducersDetail';
import listingReducer from '../features/accomodation/reducers/listingReducer';
import exportGuestBook from '../features/guest-book/reducers/exportGuestBook';
import accountEpic from '../features/account/epics';
import guestBookEpics from '../features/guest-book/epics';
import accomodationEpic from '../features/accomodation/epics';
import reservationEpic from '../features/reservation/epics';
import settingsReducer from '../features/settings/reducers';
import clientDetailsEpic from '../features/settings/epics';
import { connectRouter } from 'connected-react-router';

export const rootEpic = combineEpics(
  accountEpic,
  accomodationEpic,
  reservationEpic,
  guestBookEpics,
  clientDetailsEpic,
);

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    account: accReducers,
    userProfile: userProfile,
    accomodation: accomodationReducer,
    accomodationDetail: accomodationDetailReducer,
    modal: modalReducer,
    reservation,
    checkInForm,
    country: countriesReducer,
    exportGuestBook,
    listingState: listingReducer,
    settings: settingsReducer,
    countries,
    global,
    visaCountry,
    property: propertyReducer,
    client: clientReducer,
    listing: listingsReducer,
  });
