import createReducer from '../../../redux/createReducer';
import {
  CREATE_RESIDENTIAL_CLIENT,
  CREATE_RESIDENTIAL_CLIENT_SUCCESS,
} from '../actionTypes';
import { produce } from 'immer';
import type { ResidentialClientState } from '../model';
import type { ATCreateResidentialClientSuccess } from '../actionTypes';

const initialState: ResidentialClientState = {
  data: {
    bankAccount: '',
    invoiceAddress: '',
    // postalAddress: '',
    email: '',
    phoneNumber: '',
    contactName: '',
    ubyClientContact: '',
    ubyClientName: '',
    ubyClientNameShort: '',
    ubyUsername: '',
    ubyPassword: '',
    ubyIDUB: '',
  },
  loading: false,
};

export default createReducer(initialState, {
  [CREATE_RESIDENTIAL_CLIENT]: (state: ResidentialClientState) =>
    produce(state, draftState => {
      draftState.loading = true;
    }),
  [CREATE_RESIDENTIAL_CLIENT_SUCCESS]: (
    state: ResidentialClientState,
    action: ATCreateResidentialClientSuccess
  ) =>
    produce(state, draftState => {
      draftState.loading = false;
      draftState.data = action.res;
    }),
});
