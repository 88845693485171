// @flow
import React, { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import CircularProgress from '@material-ui/core/CircularProgress';

const Dashboard = lazy(() => import('../features/dashboard'));

const LoginForm = lazy(() => import('../features/account/containers/Login'));

const ForgotPassword = lazy(() =>
  import('../features/account/containers/ForgotPassword'),
);

const Register = lazy(() => import('../features/account/containers/Register'));

const LetDoIt = lazy(() => import('../features/account/containers/LetDoIt'));

const VerifyEmail = lazy(() => import('../features/account/containers/VerifyEmail'));

const ResetPassword = lazy(() =>
import('../features/account/containers/ResetPassword'),
);

const PublicRoute = lazy(() => import('./PublicRoute'));

const NotFound = lazy(() => import('./NotFound'));


export const email_verify_url = 'user/emailVerify';
export const let_do_it_url = 'user/hasAccount';
const Onboarding = lazy(() => import('../features/onboarding/containers/Onboarding'));

const Routes = ({ location }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Router>
        <Dashboard path="/*" />
        <PublicRoute component={Onboarding} path="onboarding/*" />
        <PublicRoute component={LoginForm} path="login" />
        <PublicRoute component={ForgotPassword} path="forgot-password" />
        <PublicRoute component={ResetPassword} path="reset-password" />
        <PublicRoute component={Register} path="register" />
        <PublicRoute component={VerifyEmail} path={email_verify_url} />
        <PublicRoute component={LetDoIt} path={let_do_it_url} />

        <NotFound default />
      </Router>
    </Suspense>
  );
};

export default Routes;
