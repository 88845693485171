import { SET_TITLE_GLOBAL } from '../actions/global';
import produce from 'immer';

export const initialState = {
  globalTitle: {
    title: null,
    label: null
  }
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_TITLE_GLOBAL:
        draft.globalTitle = action.params;
        return;
      default:
        return state;
    }
  });
