import { makeRequest } from '../redux/apiCall';
import { omit } from 'lodash';
import {
  URL_MY_TRIPS,
  URL_RESERVATIONS_GET_ALL,
  URL_RESERVATIONS_GET_DETAIL,
  URL_RESERVATIONS_UPDATE,
  URL_RESERVATIONS_CREATE,
  URL_SHARE_RESERVATION,
  URL_ADD_MASTER_GUEST,
  URL_RESERVATIONS_CHECK_IN_FORM,
  URL_UPDATE_CHECK_IN_FORM,
  URL_GET_CHECK_IN_FORM,
  URL_INVITATION_GUEST_DETAILS,
  URL_LISTINGS_GET_BY_PROPERTY_ID,
  URL_DELETE_RESERVATION,
  URL_RESERVATION_TO_POLICE,
  URL_GET_CHECKIN_FORM_SIGNATURE,
  URL_GET_INVITATIONS_GUEST,
  URL_RESERVATION_CREATE_PUBLIC,
  URL_GET_CLIENT_SIGNATURE,
} from '../shared/urls';

export const FETCH_MY_TRIPS = 'fetch_my_trips';
export const FETCH_MY_TRIPS_SUCCESS = 'fetch_my_trips_success';
export const FETCH_MY_TRIPS_FAILURE = 'fetch_my_trips_failure';

export const FETCH_RESERVATIONS = 'fetch_reservations';
export const FETCH_RESERVATIONS_SUCCESS = 'fetch_reservations_success';
export const FETCH_RESERVATIONS_FAILURE = 'fetch_reservations_failure';

export const GET_INVITATIONS_GUEST = 'get_invitations_guest';
export const GET_INVITATIONS_GUEST_SUCCESS = 'get_invitations_guest_success';
export const GET_INVITATIONS_GUEST_FAILURE = 'get_invitations_guest_failure';

export const FETCH_RESERVATION_DETAIL = 'fetch_reservation_detail';
export const FETCH_RESERVATION_DETAIL_SUCCESS = 'fetch_reservation_detail_success';
export const FETCH_RESERVATION_DETAIL_FAILURE = 'fetch_reservation_detail_failure';

export const UPDATE_RESERVATION = 'update_reservation';
export const UPDATE_RESERVATION_SUCCESS = 'update_reservation_success';
export const UPDATE_RESERVATION_FAILURE = 'update_reservation_failure';

export const CREATED_RESERVATION = 'create_reservation';
export const CREATED_RESERVATION_SUCCESS = 'create_reservation_success';
export const CREATED_RESERVATION_FAILURE = 'create_reservation_failure';

export const CREATED_PUBLIC_RESERVATION = 'create_public_reservation';
export const CREATED_PUBLIC_RESERVATION_SUCCESS = 'create_public_reservation_success';
export const CREATED_PUBLIC_RESERVATION_FAILURE = 'create_public_reservation_failure';

export const DELETE_RESERVATION = 'delete_reservation';
export const DELETE_RESERVATION_SUCCESS = 'delete_reservation_success';
export const DELETE_RESERVATION_FAILURE = 'delete_reservation_failure';

export const ADD_MASTER_GUEST = 'add_master_guest';

export const CREATE_GUEST = 'create_guest';
export const CREATE_GUEST_SUCCESS = 'create_guest_success';
export const CREATE_GUEST_FAILURE = 'create_guest_failure';

export const UPDATE_CHECK_IN_FORM = 'update_check_in_form';
export const UPDATE_CHECK_IN_FORM_SUCCESS = 'update_check_in_form_success';
export const UPDATE_CHECK_IN_FORM_FAILURE = 'update_check_in_form_failure';

export const GET_CHECK_IN_FORM = 'get_check_in_form';
export const GET_CHECK_IN_FORM_SUCCESS = 'get_check_in_form_success';
export const GET_CHECK_IN_FORM_FAILURE = 'get_check_in_form_failure';

export const DELETE_INVITATION = 'delete_invitation';
export const DELETE_INVITATION_SUCCESS = 'delete_invitation_success';
export const DELETE_INVITATION_FAILURE = 'delete_invitation_failure';

export const GET_SIGNATURE_URL = 'get_signatureUrl';
export const GET_SIGNATURE_URL_SUCCESS = 'get_signatureUrl_success';
export const GET_SIGNATURE_URL_FAILURE = 'get_signatureUrl_failure';
export const REMOVE_DEFAULT_SIGNATURE = 'remove_default_signature';

export const GET_LISTINGS_BY_PROPERTY_ID = 'get_listings_by_property_id';
export const GET_LISTINGS_BY_PROPERTY_ID_SUCCESS =
  'get_listings_by_property_id_success';
export const GET_LISTINGS_BY_PROPERTY_ID_FAILURE =
  'get_listings_by_property_id_failure';

export const CREATE_RESERVATION_TO_POLICE = 'create_reservation_to_police';
export const CREATE_RESERVATION_TO_POLICE_SUCCESS = 'create_reservation_to_police_success';
export const CREATE_RESERVATION_TO_POLICE_FAILURE = 'create_reservation_to_police_failure';

export const INVITATION_GUEST_DETAILS = 'invitation_guest_details';
export const INVITATION_GUEST_DETAILS_SUCCESS = 'invitation_guest_details_success';
export const INVITATION_GUEST_DETAILS_FAILURE = 'invitation_guest_details_failure';

export const getMyTrips = params =>
  makeRequest(
    FETCH_MY_TRIPS,
    'get',
    URL_MY_TRIPS,
    params,
    getMyTrips,
  );

export const getReservations = params =>
  makeRequest(
    FETCH_RESERVATIONS,
    'get',
    URL_RESERVATIONS_GET_ALL,
    params,
    getReservations,
  );

export const getInvitationsGuest = params =>
  makeRequest(
    GET_INVITATIONS_GUEST,
    'get',
    URL_GET_INVITATIONS_GUEST,
    params,
    getInvitationsGuest,
  );

export const getReservationDetail = params =>
  makeRequest(
    FETCH_RESERVATION_DETAIL,
    'get',
    URL_RESERVATIONS_GET_DETAIL,
    params,
    getReservationDetail,
  );

export const updateReservation = params =>
  makeRequest(
    UPDATE_RESERVATION,
    'post',
    URL_RESERVATIONS_UPDATE,
    params,
    updateReservation,
    true,
  );

export const createReservation = params =>
  makeRequest(
    CREATED_RESERVATION,
    'post',
    URL_RESERVATIONS_CREATE,
    params,
    createReservation,
  );

export const createPublicReservation = params =>
  makeRequest(
    CREATED_PUBLIC_RESERVATION,
    'post',
    URL_RESERVATION_CREATE_PUBLIC,
    params,
    createPublicReservation,
    true,
  );

export const shareReservation = params =>
  makeRequest(
    CREATE_GUEST,
    'post',
    URL_SHARE_RESERVATION,
    params,
    shareReservation,
);

export const addMasterGuest = params =>
  makeRequest(
    ADD_MASTER_GUEST,
    'post',
    URL_ADD_MASTER_GUEST,
    params,
    addMasterGuest,
);

export const deleteReservation = params =>
  makeRequest(
    DELETE_RESERVATION,
    'post',
    URL_DELETE_RESERVATION,
    params,
    deleteReservation,
    true
  );

export const checkInform = params =>
  makeRequest(
    GET_CHECK_IN_FORM,
    'post',
    URL_RESERVATIONS_CHECK_IN_FORM,
    params,
    checkInform,
  );

export const updateCheckInform = params =>
  makeRequest(
    UPDATE_CHECK_IN_FORM,
    'post',
    URL_UPDATE_CHECK_IN_FORM,
    params,
    updateCheckInform,
    true,
  );

export const getCheckInform = params =>
  makeRequest(
    GET_CHECK_IN_FORM,
    'get',
    URL_GET_CHECK_IN_FORM,
    params,
    getCheckInform,
  );

export const getInvitationGuestDetails = params =>
  makeRequest(
    INVITATION_GUEST_DETAILS,
    'get',
    URL_INVITATION_GUEST_DETAILS,
    params,
    getInvitationGuestDetails,
  );

export const deleteInvitation = (url, params) =>
  makeRequest(
    DELETE_INVITATION,
    'post',
    url,
    params,
    deleteInvitation,
    true
  );

export const getListingsByPropertyId = params =>
  makeRequest(
    GET_LISTINGS_BY_PROPERTY_ID,
    'get',
    URL_LISTINGS_GET_BY_PROPERTY_ID,
    params,
    getListingsByPropertyId,
  );

export const getSignatureUrl = params =>
  makeRequest(
    GET_SIGNATURE_URL,
    'get',
    // URL_GET_CHECKIN_FORM_SIGNATURE,
    params.url ? params.url : URL_GET_CLIENT_SIGNATURE,
    omit(params, ['url']),
    getSignatureUrl,
    false,
    true
  );

export const removeDefailtSignature = () =>
  async (dispatch, getState) => {
    dispatch({ type: REMOVE_DEFAULT_SIGNATURE });
  };
export const sendReservationToPolice = params =>
  makeRequest(
    CREATE_RESERVATION_TO_POLICE,
    'post',
    URL_RESERVATION_TO_POLICE,
    params,
    sendReservationToPolice
  )