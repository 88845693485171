import {
  ProfileInfoUrl,
  URL_CLIENT_GET_INFO,
  URL_CLIENT_GET_RESIDENTIAL_CLIENTS,
  URL_USER_PROFILE,
  URL_USER_UPDATE_PROFILE,
  URL_GET_CLIENT_SIGNATURE,
  URL_GET_HAS_ACCOUNT,
  URL_SAVE_USER_INFO,
  AccountUrls,
  URL_SIGN_UP_WITH_EMAIL,
  URL_RESET_PASSWORD_EMAIL,
  URL_CHANGE_PASSWORD,
  URL_EMAIL_VERIFY,
  URL_VERIFY_PASSWORD_OTP
} from '../shared/urls';
import { makeRequest } from '../redux/apiCall';

export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const RESET = 'reset';
export const SYSTEM_LOGIN = 'system_login';

export const GET_USER_PROFILE = 'get_user_profile';
export const GET_USER_PROFILE_SUCCESS = 'get_user_profile_success';
export const GET_USER_PROFILE_FAILURE = 'get_user_profile_failure';

export const UPDATE_USER_PROFILE = 'update_user_profile';
export const UPDATE_USER_PROFILE_SUCCESS = 'update_user_profile_success';

export const GET_CLIENT = 'get_client';
export const GET_CLIENT_SUCCESS = 'get_client_success';

export const GET_RESIDENTIAL_CLIENT = 'get_residential_client';
export const GET_RESIDENTIAL_CLIENT_SUCCESS = 'get_residential_client_success';

export const SWITCH_COMPANY_TYPE = 'switch_company_type';

export const CHANGE_PASS_WORD = 'change_pass_word';
export const CHANGE_PASS_WORD_SUCCESS = 'change_pass_word_success';
export const CHANGE_PASS_WORD_FAILURE = 'change_pass_word_failure';

export const UPDATE_CLIENT_INFO = 'update_client_info';
export const UPDATE_CLIENT_INFO_SUCCESS = 'update_client_info_success';

export const GET_CLIENT_SIGNATURE_URL = 'get_client_signature';
export const GET_CLIENT_SIGNATURE_URL_SUCCESS = 'get_client_signature_success';
export const REMOVE_CLIENT_SIGNATURE = 'remove_client_signature';


export const GET_HAS_ACCOUNT = 'get_has_account';

export const SAVE_USER_INFO = 'save_user_info';

export const GET_SIGNATURE = 'GET_SIGNATURE';

export const SIGN_UP_WITH_EMAIL = 'sign_up_with_email';
export const SIGN_UP_WITH_EMAIL_SUCCESS = 'sign_up_with_email_success';
export const SIGN_UP_WITH_EMAIL_FAILURE = 'sign_up_with_email_failure';

export const RESET_PASSWORD_EMAIL = 'reset_password_email';

export const CHANGE_PASSWORD = 'change_password';
export const VERIFY_PASSWORD_OTP = 'verify_password_otp';

export const EMAIL_VERIFY = 'email_verify';
export const EMAIL_VERIFY_SUCCESS = 'email_verify_success';
export const EMAIL_VERIFY_FAILURE = 'email_verify_failure';

export const login = params => ({ type: LOGIN, params });
export const logout = () => ({ type: LOGOUT });
export const reset = () => ({ type: RESET });

export const emailVerify = params =>
  makeRequest(
    EMAIL_VERIFY,
    'post',
    URL_EMAIL_VERIFY,
    params,
    emailVerify,
  );

export const changePasswordUser = params =>
  makeRequest(
    CHANGE_PASSWORD,
    'post',
    URL_CHANGE_PASSWORD,
    params,
    changePasswordUser,
  );

export const systemLogin = params =>
  makeRequest(
    SYSTEM_LOGIN,
    'post',
    AccountUrls.LOG_IN,
    params,
    systemLogin,
  );

export const signupWithEmail = params =>
  makeRequest(
    SIGN_UP_WITH_EMAIL,
    'post',
    URL_SIGN_UP_WITH_EMAIL,
    params,
    signupWithEmail
  );

export const confirmEmail = params =>
  makeRequest(
    RESET_PASSWORD_EMAIL,
    'post',
    URL_RESET_PASSWORD_EMAIL,
    params,
    confirmEmail
  );

  export const verifyPasswordOtp = params =>
  makeRequest(
    VERIFY_PASSWORD_OTP,
    'post',
    URL_VERIFY_PASSWORD_OTP,
    params,
    verifyPasswordOtp
  );


export const getClientInfo = () =>
  makeRequest(GET_CLIENT, 'get', URL_CLIENT_GET_INFO, null, getClientInfo);

export const getProfile = () =>
  makeRequest(
    GET_USER_PROFILE,
    'get',
    URL_USER_PROFILE,
    null,
    getProfile,
  );

export const updateProfile = params =>
  makeRequest(
    UPDATE_USER_PROFILE,
    'post',
    URL_USER_UPDATE_PROFILE,
    params,
    updateProfile,
  );

export const getResidentialClient = () =>
  makeRequest(
    GET_RESIDENTIAL_CLIENT,
    'get',
    URL_CLIENT_GET_RESIDENTIAL_CLIENTS,
    null,
    getResidentialClient,
  );

export const switchCompanyType = propertyManager => ({
  type: SWITCH_COMPANY_TYPE,
  propertyManager,
});

export const changePassword = params => ({
  type: CHANGE_PASS_WORD,
  params,
});

export const updateClientInfo = params =>
  makeRequest(
    UPDATE_CLIENT_INFO,
    'post',
    ProfileInfoUrl.UPDATE_CLIENT_INFO_URL,
    params,
    updateClientInfo,
  );

export const hasAccount = params =>
  makeRequest(
    GET_HAS_ACCOUNT,
    'get',
    URL_GET_HAS_ACCOUNT,
    params,
    hasAccount,
    false,
    false,
    false,
  );

export const saveUserInfo = params =>
  makeRequest(
    SAVE_USER_INFO,
    'post',
    URL_SAVE_USER_INFO,
    params,
    saveUserInfo,
    false,
    false,
    false,
  );

export const getSignatureUrl = params =>
  makeRequest(
    GET_CLIENT_SIGNATURE_URL,
    'get',
    URL_GET_CLIENT_SIGNATURE,
    params,
    getSignatureUrl,
    false,
    true
  );

export const getSignature = params =>
  makeRequest(
    GET_SIGNATURE,
    'get',
    URL_GET_CLIENT_SIGNATURE,
    params,
    getSignature,
    false,
    true
  );

export const removeDefailtSignature = params =>
  async (dispatch, getState) => {
    dispatch({ type: REMOVE_CLIENT_SIGNATURE, params });
  };
